import React, { useState } from "react";
import "./style.css";
import { NavLink } from "react-router-dom";
import logo from "../../images/Header/logo.svg";

function Header() {
  const [showNavbar, setShowNavbar] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  return (
    <nav className="navbar">
      <div className="container">
        <NavLink to="/">
          <img src={logo} alt="Logo" />
        </NavLink>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <span>=</span>
        </div>
        <div className={`nav-elements  ${showNavbar && "active"}`}>
          <ul className="nav-elements-inicial">
            <li className="dropdown">
              <button className="dropbtn">Soluções em Contabilidade</button>
              <div className="dropdown-content">
                <NavLink to="/contabil">Contábil</NavLink>
                <NavLink to="/folha-de-pagamento">Folha de Pagamento</NavLink>
                <NavLink to="/fiscal">Fiscal</NavLink>
                <NavLink to="/patrimonial">Patrimônio</NavLink>
                <NavLink to="/simplesnacionalfacil">
                  <b>Simples Nacional Fácil</b>
                </NavLink>
              </div>
            </li>
            <li className="dropdown">
              <button className="dropbtn">Soluções em Nuvem</button>
              <div className="dropdown-content">
                <NavLink to="/methodus">Methodus</NavLink>
                <NavLink to="/eficatus">e-Eficatus</NavLink>
                <NavLink to="/busca">e-Busca</NavLink>
                <NavLink to="/governanca-cadastral">
                  Governança Cadastral
                </NavLink>
                <NavLink to="/compliance-fiscal">Compliance Fiscal</NavLink>
                <NavLink to="/recuperacao-tributaria">
                  Recuperação Tributária
                </NavLink>
                <NavLink to="/exclusao-icms">Exclusão do ICMS</NavLink>
                <NavLink to="/erp">Exactus ERP</NavLink>
                <NavLink to="/five">Exactus Five</NavLink>
              </div>
            </li>
            <li>
              <NavLink to="/certificado">Certificado Digital</NavLink>
            </li>
            <li>
              <NavLink to="/solucoes">Soluções para Empresa</NavLink>
            </li>
            <li className="nav-zactus">
              <NavLink to="https://www.zactus.com.br" target="_blank">
                Zactus
              </NavLink>
            </li>
          </ul>
          <ul className="nav-elements-final">
            <li>
              <NavLink to="http://crm.exactus.com.br" target="_blank">
                Suporte
              </NavLink>
            </li>
            <li>
              <NavLink to="http://apps.exactus.com.br" target="_blank">
                Login
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Header;
