import React, { useContext } from "react";
import "./style.css";
import logo from "../../images/Footer/logo-branco.svg";
import gcert from "../../images/Footer/gcert.svg";
import whik from "../../images/Footer/whik.svg";
import facebook from "../../images/Footer/facebook.svg";
import instagram from "../../images/Footer/instagram.svg";
import linkedin from "../../images/Footer/linkedin.svg";
import { NavLink } from "react-router-dom";
import { EmailContext } from "../../context/EmailContext";
import { useLocation } from "react-router-dom";

function Footer({ footerWhik, footerValid, certificado }) {
  const location = useLocation();
  const { setContabilidade, setOrigem } = useContext(EmailContext);
  const handleContabilidade = () => {
    setContabilidade("Parceiro");
    setOrigem("Assunto: Parceiro");
  };
  const dataAtual = new Date().getFullYear();
  return (
    <footer>
      <div className="footer-itens">
        <ul>
          <li>
            <NavLink to="/">
              <img src={logo} alt="Logo" className="logo" />
            </NavLink>
          </li>
          <li>
            <b>Exactus Intelligence</b>
          </li>
          {location.pathname === "/politica-de-privacidade" && (
            <li>CNPJ: 81.760.878/0001-27</li>
          )}

          <li>Tel.: 43 3372.7000</li>
          <li>End.: Av. Rio Branco, 444</li>
          <li>Londrina Pr</li>
        </ul>
        <ul className="footer-paginas">
          <li>
            <NavLink to="/contato">
              <button
                onClick={() => handleContabilidade()}
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  padding: 0,
                  color: "white",
                  fontWeight: "bold",
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
              >
                Seja um Parceiro EXACTUS
              </button>
            </NavLink>
          </li>
          <li>Soluções em Contabilidade</li>
          <li>Soluções em Nuvem</li>
          <li>
            <NavLink to="/planos-e-assinatura">Planos e Assinatura</NavLink>
          </li>
          <li>
            <NavLink to="/certificado">Certificado Digital</NavLink>
          </li>
          <li>
            <NavLink to="/solucoes">Soluções para Empresa</NavLink>
          </li>
        </ul>
        <ul className="footer-paginas">
          <li>
            <NavLink to="/institucional">
              Mais sobre a <b>Exactus</b>
            </NavLink>
          </li>
          <li>
            <NavLink to="/politica-de-privacidade">
              Política de Privacidade
            </NavLink>
          </li>
          <li>
            <NavLink to="/politica-de-cookies">Política de Cookies</NavLink>
          </li>
          {certificado && (
            <>
              <li>
                <NavLink to="/politica-de-reembolso">
                  Política de Reembolso
                </NavLink>
              </li>
              <li>
                <NavLink to="/politica-de-garantia">
                  Política de Garantia
                </NavLink>
              </li>
            </>
          )}
        </ul>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {footerValid && footerWhik && (
            <>
              <div className="footer-parceiros">
                <p>
                  <b>Parceiros:</b>
                </p>
                <img src={whik} alt="Logo da Whik" />
              </div>
            </>
          )}
          {location.pathname === "/compra" ||
            (location.pathname === "/politica-de-reembolso" && (
              <div className="footer-parceiros">
                <img
                  style={{ width: "80px" }}
                  src={gcert}
                  alt="Logo da Valid"
                />
              </div>
            ))}
          {!footerValid && footerWhik && (
            <div className="footer-parceiros">
              <p>
                <b>Parceiros:</b>
              </p>
              <img src={whik} alt="Logo da Whik" />
            </div>
          )}
          <div className="footer-redes-sociais">
            <NavLink
              to="https://m.facebook.com/profile.php/?id=100064740574406&name=xhp_nt__fb__action__open_user"
              target="_blank"
            >
              <img
                src={facebook}
                alt="Logo do Facebook"
                style={{ width: "31px", height: "31px" }}
              />
            </NavLink>
            <NavLink
              to="https://www.instagram.com/exactus.intelligence?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
              target="_blank"
            >
              <img
                src={instagram}
                alt="Logo do Instagram"
                style={{ width: "31px", height: "31px" }}
              />
            </NavLink>
            <NavLink
              to="https://br.linkedin.com/company/exactus-software-oficial"
              target="_blank"
            >
              <img
                src={linkedin}
                alt="Logo do Linkedin"
                style={{ width: "31px", height: "31px" }}
              />
            </NavLink>
          </div>
        </div>
      </div>
      <p className="footer-final">
        © {dataAtual} Exactus Intelligence. Todos os Direitos Reservados.
      </p>
    </footer>
  );
}

export default Footer;
